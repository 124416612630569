import { Contact, Fees, FeesV2, ID } from '.';
import { userUtils, utils } from '../helpers';
import { CampaignBuilderPageProps, CampaignBuilderTextOption, CampaignBuilderTextOptions } from '../pages/campaigns/builder/cmp.builder.types';
import { Audience } from './audience';
import { BudgetForecastOutput } from './forecast';
import { ChangeSet, RecipeInfo } from './recipes';

export type BulkUpdateItem = { id: string; action: string; value: string | number | boolean };

type ConvesionTypeData = {
	description: string;
	name: string;
	defaultEnabled: boolean;
	template: CampaignChannelType | undefined;
};
export const CampaignConversionActionMap = {
	text_received: {
		defaultEnabled: true,
		name: 'Text received',
		description: 'After a text is sent',
		template: 'templates',
	} as ConvesionTypeData,
	text_click_to_open: {
		defaultEnabled: true,
		name: 'Opened text',
		description: 'After they open a text',
		template: 'templates',
	} as ConvesionTypeData,
	text_sent: {
		defaultEnabled: true,
		name: 'Customer response via text',
		description: 'After customer responds to a text',
		template: 'templates',
	} as ConvesionTypeData,
	text_click: {
		defaultEnabled: true,
		name: 'Landing page viewed',
		description: 'After landing page link was clicked',
		template: 'templates',
	} as ConvesionTypeData,
	email_received: {
		defaultEnabled: true,
		name: 'Email received',
		description: 'After email is sent',
		template: 'emailTemplate',
	} as ConvesionTypeData,
	email_open: {
		defaultEnabled: true,
		name: 'Opened email',
		description: 'After an email was opened',
		template: 'emailTemplate',
	} as ConvesionTypeData,
	push_received: {
		defaultEnabled: true,
		name: 'Native push received',
		description: 'After native app push delivered',
		template: 'pushTemplate',
	} as ConvesionTypeData,
	push_open: {
		defaultEnabled: true,
		name: 'Opened native push',
		description: 'After a push was opened',
		template: 'pushTemplate',
	} as ConvesionTypeData,
	// ad_received: {
	// 	defaultEnabled: false,
	// 	name: "Screen ad received",
	// 	description: "After an app ad or screen ad was shown",
	// 	template: 'adTemplate'
	// } as ConvesionTypeData,
	browser_received: {
		defaultEnabled: true,
		name: 'Browser push received',
		description: 'After browser push delivered',
		template: 'browserTemplate',
	} as ConvesionTypeData,
	direct_mail_received: {
		defaultEnabled: true,
		name: 'Direct mail received',
		description: 'After a direct mail letter was delievered',
		template: 'snailMailTemplate',
	} as ConvesionTypeData,
	TerpliReview: {
		defaultEnabled: false,
		name: 'Terpli review left',
		description: 'After a Terpli review',
		template: undefined,
	} as ConvesionTypeData,
	TerpliRecommendation: {
		defaultEnabled: false,
		name: 'Terpli recommendation left',
		description: 'After a Terpli recommendation',
		template: undefined,
	} as ConvesionTypeData,
	TerpliReviewDiscount: {
		defaultEnabled: false,
		name: 'Terpli review discount',
		description: 'After a Terpli review discount',
		template: undefined,
	} as ConvesionTypeData,
	TerpliRecommendationDiscount: {
		defaultEnabled: false,
		name: 'Terpli recommendation discount',
		description: 'After a Terpli recommendation discount',
		template: undefined,
	} as ConvesionTypeData,
	call_answer: {
		defaultEnabled: true,
		name: 'Voice drop answered',
		description: 'After call was answered by a person',
		template: 'voiceTemplate',
	} as ConvesionTypeData,
	call_vm: {
		defaultEnabled: true,
		name: 'Voice drop hit voicemail',
		description: 'After call went to voicemail',
		template: 'voiceTemplate',
	} as ConvesionTypeData,
	call_complete: {
		defaultEnabled: true,
		name: 'Voice drop completed',
		description: 'After a voice drop is completed',
		template: 'voiceTemplate',
	} as ConvesionTypeData,
} as const;

export type ConversionAction = keyof typeof CampaignConversionActionMap;

const getTiers = () => ({
	currentMarketingPlan: userUtils.getPackageTier('marketing'),
	hasMarketingStarter: userUtils.hasPackageTier('marketing', 'starter'),
	hasMarketingPro: userUtils.hasPackageTier('marketing', 'pro'),
	hasMarketingEnterprise: userUtils.hasPackageTier('marketing', 'enterprise'),
});

//* Users will be exempt from email/app channel requirments
const exceptions = ['1749', '1207', '2890', '2891', '2892', '3019', '1614'];

export const CampaignChannelsMap = {
	emailTemplate: {
		icon: 'envelope',
		shortName: 'Email',
		description: 'Email',
		defaultPriority: 0,
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costEmail,
				sends: estimate.sendEstimates?.countEmail,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return audience.optInEmailSize || 0;
		},
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors = [],
				tiers = getTiers();
			if (exceptions.includes(args.uid) || utils.isDemo(args.uid)) return [];
			if (!tiers.hasMarketingStarter) {
				return [`Email is not available on your current plan. Requires starter plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			if (!args.sendgridDomainCheck?.emailReady && !utils.isBrand()) {
				errors.push(`Email is not available until you verify your domain. Please verify your domain in the settings page.`);
			}
			if (utils.initialUser()?.agencyID === '1434') errors.push(`Email is not available`);
			return errors;
		},
		hasPreContent: (channel: EmailTemplate, campaign: Campaign): boolean => {
			return !!channel.subject;
		},
		hasContent: (channel: EmailTemplate, campaign: Campaign): boolean => {
			return !!channel.html || !!channel.json;
		},
		validate: (channel: EmailTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			if (!channel.subject) errors.push('Subject is required');
			if (!channel.from) errors.push('From is required');
			if (!channel.fromName) errors.push('From name is required');
			if (!channel.html) errors.push('Content is required');
			return errors;
		},
		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerSentEmail || 0;
			}
			return (fees as FeesV2).messagingDefaults?.email || 0;
		},
	},
	voiceTemplate: {
		icon: 'phone',
		shortName: 'Voice',
		description: 'Voice call',
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costVoice,
				sends: estimate.sendEstimates?.countVoice,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return audience.optInVoiceSize || 0;
		},
		defaultPriority: 1,
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (!tiers.hasMarketingStarter) {
				return [`Voice is not available on your current plan. Requires starter plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			return errors;
		},
		hasPreContent: (channel: VoiceTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: VoiceTemplate, campaign: Campaign): boolean => {
			return !!channel.voiceFile || (!!channel.lo && !!channel.ttv);
		},
		validate: (channel: VoiceTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			if (channel.ttv) {
				if (!channel.lo) errors.push('Language is required');
			} else if (!channel.voiceFile) {
				errors.push('Voice file is required');
			}
			return errors;
		},

		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerCallMinute || 0;
			}
			return (fees as FeesV2).messagingDefaults?.call || 0;
		},
	},
	templates: {
		icon: 'comment-lines',
		shortName: 'Text',
		description: 'Text message',
		// defaultItems: { landingType: "HTML", } as Partial<SMSTemplate>,
		defaultItems: (campaign: Campaign) => {
			const isAgnostic = CampaignBuilderTextOptions.regular.isAvailable(campaign);
			const defaultTemplate: SMSTemplate = {
				landingType: campaign.optinCampaign || campaign.emailOptinCampaign ? 'RAW' : 'HTML',
				optionType: isAgnostic ? 'regular' : 'regulated',
				msgContainer: utils.user.msgContainer ?? '',
			};
			if (isAgnostic) {
				defaultTemplate.agnostic = true;
				defaultTemplate.msgContainer = undefined;
				defaultTemplate.landingType = undefined;
				defaultTemplate.landingHTML = undefined;
				defaultTemplate.landingDesign = undefined;
			}
			return defaultTemplate;
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			const smsCost = estimate.budgetEstimates?.costSMS || 0;
			const mmsCost = estimate.budgetEstimates?.costMMS || 0;
			const smsSends = estimate.sendEstimates?.countSMS || 0;
			const mmsSends = estimate.sendEstimates?.countMMS || 0;
			return {
				cost: smsCost + mmsCost,
				sends: smsSends + mmsSends,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return audience.optInPhoneSize || 0;
		},
		defaultPriority: 2,
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (!tiers.hasMarketingStarter) {
				return [`Text is not available on your current plan. Requires starter plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			return errors;
		},
		hasPreContent: (channel: SMSTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: SMSTemplate, campaign: Campaign): boolean => {
			const MMSOnly = campaign.templates?.mmsOnly;
			const isHTML = channel.landingType === 'HTML';
			const isLegacy = !isHTML || campaign.optinCampaign;
			if (channel.optionType === 'regular') {
				return !!channel.body;
			}
			if (MMSOnly) {
				return !!channel.mmsMediaURL;
			}
			if (isLegacy) {
				return !!channel.body;
			}
			return !!channel.landingHTML || !!channel.landingDesign;
		},
		validate: (channel: SMSTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			return errors;
		},
		getCostPerSend: (isMMS?: boolean) => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				if (isMMS) return (fees as Fees).chargePerSentMMS || 0;
				return (fees as Fees).chargePerSentSMS || 0;
			}
			if (isMMS) return (fees as FeesV2).messagingDefaults?.mms || 0;
			return (fees as FeesV2).messagingDefaults?.sms || 0;
		},
	},
	pushTemplate: {
		icon: 'mobile',
		shortName: 'Native Push',
		description: 'Native app push',
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costPush,
				sends: estimate.sendEstimates?.countPush,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return audience.optInPushSize || 0;
		},
		defaultPriority: 3,
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (['3164', ...exceptions].includes(args.uid) || utils.isDemo(args.uid)) return [];
			if (!tiers.hasMarketingStarter) {
				return [`Push is not available on your current plan. Requires starter plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			if (!utils.user?.metadata?.hasNativeApp && !utils.user?.hasCustomApp && !utils.isBrand()) {
				return [`Push is not available until you have a native app. Please contact support to get started.`];
			}
			return errors;
		},
		hasPreContent: (channel: PushTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: PushTemplate, campaign: Campaign): boolean => {
			return !!channel.body && !!channel.title;
		},
		validate: (channel: PushTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			return errors;
		},
		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerSentPush || 0;
			}
			return (fees as FeesV2).messagingDefaults?.push || 0;
		},
	},
	browserTemplate: {
		icon: 'browser',
		shortName: 'Browser Push',
		description: 'Browser push',
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costBrowser,
				sends: estimate.sendEstimates?.countBrowser,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return 0;
		},
		defaultPriority: 4,
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (!tiers.hasMarketingStarter) {
				return [`Browser push is not available on your current plan. Requires starter plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			return errors;
		},
		hasPreContent: (channel: PushTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: BrowserTemplate, campaign: Campaign): boolean => {
			return !!channel.body && !!channel.title;
		},
		validate: (channel: BrowserTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			return errors;
		},
		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerSentBrowser || 0;
			}
			return 0;
		},
	},
	snailMailTemplate: {
		icon: 'mailbox',
		shortName: 'Direct Mail',
		description: 'Direct mail',
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costMail,
				sends: estimate.sendEstimates?.countPostal,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return 0;
		},
		defaultPriority: 5,
		isVisible: () => true,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (!tiers.hasMarketingPro) {
				return [`Direct mail is not available on your current plan. Requires pro plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			return errors;
		},
		hasPreContent: (channel: PushTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: SnailMailTemplate, campaign: Campaign): boolean => {
			return !!channel.front && !!channel.back && !!channel.size;
		},
		validate: (channel: SnailMailTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			return errors;
		},
		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerSentSnailMail || 0;
			}
			return (fees as FeesV2).messagingDefaults?.directMail || 0;
		},
	},
	adTemplate: {
		icon: 'tv',
		shortName: 'Screen Ad',
		description: 'Screen ad',
		defaultItems: (campaign: Campaign) => {
			return {};
		},
		getBudgetInfo: (estimate: BudgetForecastOutput) => {
			return {
				cost: estimate.budgetEstimates?.costAd,
				sends: estimate.sendEstimates?.countAd,
			};
		},
		getAudienceOptins(audience: Audience): number {
			return 0;
		},
		defaultPriority: 6,
		isVisible: () => false,
		isLocked: (args: CampaignBuilderPageProps) => {
			const errors: string[] = [],
				tiers = getTiers();
			if (!tiers.hasMarketingPro) {
				return [`Screen ads are not available on your current plan. Requires pro plan or higher. Current plan: ${tiers.currentMarketingPlan}`];
			}
			return errors;
		},
		hasPreContent: (channel: PushTemplate, campaign: Campaign): boolean => {
			return true;
		},
		hasContent: (channel: AdTemplate, campaign: Campaign): boolean => {
			return false;
		},
		validate: (channel: AdTemplate, campaign: Campaign): string[] => {
			const errors: string[] = [];
			return errors;
		},
		getCostPerSend: () => {
			const fees = userUtils.getUserFees();
			if (userUtils.hasLegacyBilling()) {
				return (fees as Fees).chargePerCPMAds || 0;
			}
			return 0;
		},
	},
} as const;

export const CampaignChannels = Object.keys(CampaignChannelsMap) as CampaignChannelType[];
export type CampaignChannelType = keyof typeof CampaignChannelsMap;
export type CampaignChannel = (EmailTemplate | SMSTemplate | BrowserTemplate | AdTemplate | PushTemplate | VoiceTemplate) & { inUse?: boolean };

export type CampaignMap = Partial<{ [key in CampaignChannelType]: Campaign }>;

export type CampaignRealitives = Partial<{ [key in CampaignChannelType]: string }>;
export interface Campaign {
	id?: string;
	type?: string;
	userID?: string;
	name?: string;
	timezone?: string;
	avatar?: string;
	storeIDs?: string[];
	storeIDHash?: { [key: ID]: ID[] };
	exclude?: string[];
	audiences?: string[];
	updated?: number;
	created?: number;
	recurDays?: number;
	capPerDay?: number;
	approved?: number;
	giftPoints?: number;
	triggers?: Triggers;
	optinCampaign?: boolean;
	emailOptinCampaign?: boolean;
	default?: boolean;
	archived?: boolean;
	isActive?: boolean;
	recipe?: RecipeInfo;
	isLocked?: boolean; // is this locked (being used in a recipe)
	lockedReason?: string; // why is this locked
	recipes?: { [key: string]: boolean }; // the id of the receipes that this campaign is in
	lastMessage?: number;
	tempID?: string;
	conversionWindow?: number;
	conversionActions?: ConversionAction[] | string[];
	clonedFrom?: string;
	messageExpiry?: number;
	messageExpiryUnit?: TimeUnit;
	changeSet?: CampaignChangeSet;
	lifetimeCap?: number;

	// Scheduleing options
	blast?: boolean;
	scheduled?: number;
	sendAtTimePredicted?: boolean;
	dateRange?: DateRange;
	sendWindow?: SendWindow;
	enableQueue?: boolean;

	pushTemplate?: PushTemplate;
	emailTemplate?: EmailTemplate;
	templates?: SMSTemplate;
	adTemplate?: AdTemplate;
	browserTemplate?: BrowserTemplate;
	snailMailTemplate?: SnailMailTemplate;
	voiceTemplate?: VoiceTemplate;

	summary?: CampaignStats;

	pendingApproval?: boolean; // ! added and used in dash
	contextUpdated?: number; // ! Added by dash to track when the context was last updated

	relations?: CampaignRealitives;

	draft?: boolean; // ! Added by dash to track if this is a draft
	tags?: string[];
	billingStoreID?: string;
}

export interface CampaignLite {
	pushTemplate?: LiteTemplate;
	voiceTemplate?: LiteTemplate;
	emailTemplate?: LiteTemplate;
	templates?: LiteTemplate;
	adTemplate?: LiteTemplate;
	browserTemplate?: LiteTemplate;
	snailMailTemplate?: LiteTemplate;
	blast?: boolean;
	draft?: boolean;
	isActive?: boolean;
	archived?: boolean;
	recurDays?: number;
	scheduled?: number;
	conversionWindow?: number;
	id: string;
	userID?: string;
	recipeOwnerID?: string;
	recipeInfo?: RecipeInfo;
}

export interface LiteTemplate {
	landingType?: string;
	mediaURL?: string;
	mediaContentType?: string;
	mmsMediaURL?: string;
	mmsMediaContentType?: string;
	priority?: number;
	adSize?: string;
	thirdPartyPlacement?: boolean;
}

export interface CampaignUID {
	triggers?: Triggers;
	adTemplate?: XLiteTemplate;
	pushTemplate?: XLiteTemplate;
	voiceTemplate?: XLiteTemplate;
	emailTemplate?: XLiteTemplate;
	templates?: XLiteTemplate;
	browserTemplate?: XLiteTemplate;
	snailMailTemplate?: XLiteTemplate;
	messageExpiry?: number;
	messageExpiryUnit?: TimeUnit;
	dateRange?: DateRange;
	sendWindow?: SendWindow;
	userID?: string;
	id: string;
	name?: string;
	blast?: boolean;
	draft?: boolean;
	relations?: CampaignRealitives;
	archived?: boolean;
	isActive?: boolean;
	recurDays?: number;
	scheduled?: number;
	created?: number;
	updated?: number;
	recipe?: RecipeInfo;
	isLocked?: boolean;
	lockedReason?: string;
	tags?: string[];

	pendingApproval?: boolean; // ! added and used in dash

	lastMessage?: number;
	summary?: CampaignStats;
	key?: string;
}

export type XLiteTemplate = {
	priority?: number;
	inUse?: boolean;
};

export type ChannelTemplate = {
	id: string;
	userID: string;
	name: string;
	created: number;
	updated: number;
	archived: boolean;
	pushTemplate?: PushTemplate;
	emailTemplate?: EmailTemplate;
	templates?: SMSTemplate;
	smsTemplate?: SMSTemplate; // Can be in this form
	adTemplate?: AdTemplate;
	browserTemplate?: BrowserTemplate;
	snailMailTemplate?: SnailMailTemplate;
	voiceTemplate?: VoiceTemplate;
};

export enum TimeUnit {
	Days = 0,
	Hours,
}

export interface CampaignGroup {
	campaigns?: string[];
	id?: string | -1;
	userID?: string;
	name?: string;
	path?: string;
	created?: number;
	updated?: number;
	archived?: boolean;
	splitCampaigns?: boolean;
	key?: ID;
}

interface CampaignChangeSet extends ChangeSet {
	diffRetailer?: Campaign;
	diffBrand?: Campaign;
}

export interface GeoLoc {
	uuid: any;
	name: string;
	lat: number;
	lng: number;
	radius: number;
}
export interface Triggers {
	abandonedCart?: boolean;
	postSale?: boolean;
	geoFence?: boolean;
	geoFenceConditions?: {
		boundaries: GeoLoc[];
		frequencyCap: number;
	};
	birthday?: boolean;
}

export interface DateRange {
	start?: number;
	end?: number;
	active?: boolean;
}
export interface SendWindow {
	start?: number;
	end?: number;
}
export type LandingType = 'HTML' | 'RAW';

export interface SMSTemplate {
	optionType?: CampaignBuilderTextOption | undefined;
	agnostic?: boolean;
	body?: string;
	timezone?: string;
	mediaURL?: string;
	mediaContentType?: string;
	msgContainer?: string;
	inUse?: boolean;
	priority?: number;
	scheduled?: number;
	recurDays?: number;
	capPerDay?: number;
	isMMS?: boolean;
	mmsMediaURL?: string;
	landingHTML?: string;
	landingDesign?: any;
	landingType?: LandingType;
	messageExpiry?: number; // used only for allowing the template to be saved if this is the only thing changed. real state sent to server is in Campaign object
	messageExpiryUnit?: TimeUnit; // used only for allowing the template to be saved if this is the only thing changed. real state sent to server is in Campaign object
	dynamicContent?: DynamicContentFilter;
	mmsOnly?: boolean;
	slugs?: string[];
}

export interface AdTemplate {
	thirdParty?: boolean;
	retailerTarget?: any;
	budget?: number;
	adsPerCustomer?: number;
	clickthrough?: string;
	mediaURL?: string;
	mediaContentType?: string;
	media?: { [size: string]: { url?: string; contentType?: string } };
	learn?: string;
	shop?: string;
	largeBanner?: boolean;
	showButtons?: boolean;
	shopButtonText?: string;
	shopButtonStyle?: string;
	learnButtonText?: string;
	learnButtonStyle?: string;
	inUse?: boolean;
	priority?: number;
	adSize?: 'app' | 'tv';
}

export interface AdTemplateOut extends AdTemplate {
	userID: string;
	cmpID: string;
	publisherUserID: string;
	impressionTracker: string;
}

export interface EmailTemplate {
	fromName?: string;
	preheader?: string;
	html?: string;
	subject?: string;
	from?: string;
	json?: any;
	inUse?: boolean;
	priority?: number;
	custom?: boolean;
	dynamicContent?: DynamicContentFilter;
	slugs?: string[];
}

export interface BrowserTemplate {
	title?: string;
	body?: string;
	link?: string;
	mediaURL?: string; // Set when Get is called if not using media lib
	mediaContentType?: string; // Set when Get is called if not using media lib
	inUse?: boolean;
	priority?: number;
}

export interface PushTemplate {
	title?: string;
	body?: string;
	mediaURL?: string; // Set when Get is called if not using media lib
	mediaContentType?: string; // Set when Get is called if not using media lib
	inUse?: boolean;
	priority?: number;
}
export interface SnailMailTemplate {
	front?: string; // html string, lob template id, or remote file url
	back?: string; // html string, lob template id, or remote file url
	size?: string; // "4x6" "6x9" "6x11"
	inUse?: boolean;
	priority?: number;
	currentResident?: boolean;
}

export interface VoiceTemplate {
	ttv?: string;
	voiceFile?: string;
	inUse?: boolean;
	priority?: number;
	lo?: string;
	discountTemplate?: string;
}

export interface UnsubChannels {
	text?: number;
	email?: number;
	browser?: number;
}

export interface CampaignStats {
	linkClicks: { [key: string]: number };
	conversionData: ConversionValue[];
	conversionValueData: GeoValue[];
	unsubCategories: GeoValue[];
	msgsSent: number;
	msgsReceived: number;
	clicks: number;
	lpClicks: number;
	redemptions: number;
	discountRedemptions: number;
	unsubscribes: number;
	unsubChannels: {
		text: number;
		email: number;
		browser: number;
		voice: number;
	};
	brandConvs: number;
	convs: number;
	uniqueClicks: number;
	uniqueLPClicks: number;
	mmsCost: number;
	smsCost: number;
	revenue: number;
	brandRevenue: number;
	retailRevenue?: number;
	uniqueUsers: number;
	remainingUsers: number;
	totalEligible: number;
	msgsErrored: number;
	inserted: number;
	emailsSent: number;
	emailsErrored: number;
	emailOpens: number;
	uniqueEmailOpens: number;
	uniqueEmails: number;
	emailCost: number;

	browsersSent: number;
	browsersErrored: number;
	uniqueBrowsers: number;
	browserCost: number;

	thirdPartyAdsSent: number;
	thirdPartyAdsErrored: number;
	thirdPartyAdsCost: number;
	uniqueThirdPartyAds: number;

	adsCost: number;
	adsSent: number;
	adsErrored: number;
	uniqueAds: number;

	pushesSent: number;
	pushesErrored: number;
	uniquePushes: number;
	pushCost: number;
	pushOpens: number;
	uniquePushOpens: number;

	snailMailMailed: number;
	snailMailInTransit: number;
	snailMailInLocalArea: number;
	snailMailDelivered: number;
	snailMailReRouted: number;
	snailMailReturnedToSender: number;
	snailMailCost: number;

	callsSent: number;
	callsErrored: number;
	uniqueCalls: number;
	callsCost: number;
	callsAnswered: number;
	callCompleted: number;
	callsVM: number;
	avgCallDuration: number;

	username?: string;

	brandCost?: number;
	totalCost?: number;

	mmsCredits?: number;
	smsCredits?: number;
	emailCredits?: number;
	browserCredits?: number;
	snailMailCredits?: number;
	pushCredits?: number;
	callCredits?: number;

	campaignID?: string;
	campaignName?: string;
	recipe?: RecipeInfo;
}
export interface ConversionValue {
	label?: ConversionAction;
	count?: number;
	revenue?: number;
	percentage?: number;
}

export interface GeoValue {
	label?: string;
	subLabel?: string;
	count?: number;
	revenue?: number;
	perc?: number;
}

export interface SendgridVerified {
	domain: string;
	subdomain: string;
	sender: string;
}

export interface SendgridDomainCheck {
	verified: SendgridVerified[];
	emailReady: boolean;
}

export interface DynamicContentFilter {
	store?: number[];
	categories?: string[];
	quantityMin?: number;
	blockCount?: number;
	priceMin?: number;
	priceMax?: number;
	contentType?: 'mostPurchased' | 'abandoned' | 'recommended' | 'all';
	brands?: string[];
	brandPerc?: number;
}

export type LanguageType = {
	key: string; // key
	lngc: string; // languageCode
	g: string; // gender
	cn: string; // countryName
	lngn: string; // languageName
};

export type CampaignTag = {
	id: string;
	name: string;
	color: string;
};

export type ReportConversion = {
	channel: string;
	products: string[];
	categories: string[];
	online: boolean;
	grossRev: number;
	netRev: number;
};

export type CleanedReportConversion = {
	channel: string;
	products: string;
	categories: string;
	online: boolean;
	grossRev: number;
	netRev: number;
};

export type CampaignQueueSnapshot = {
	contact: Contact[];
	sent: number;
	total: number;
};
